import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointXl, sbkCoreBreakpointMd} from "@/tokens/variables";

const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);
const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);

export const sbkNavFlyoutItemStyle = css`
    :host {
        display: block;
        break-inside: avoid-column;
        border-top: var(--sbkFlyoutListItemDivider);
    }

    :host(:last-of-type) {
        border-bottom: var(--sbkFlyoutListItemDivider);
    }

    :host(.border-bottom) {
        border-bottom: var(--sbkFlyoutListItemDivider);
    }

    /* Safari workaround */
    @media (max-width: ${breakpointXl}) {
        :host(.no-border) {
            border-top: unset;
        }

        :host(.border-bottom) {
            border-bottom: unset;
        }

        :host(:last-of-type) {
            border-bottom: unset;
        }
    }


    .nav-flyout-item {
        padding: var(--sbkFlyoutListItemSpacingLargeScrGapVertical) 0;
        font: var(--sbkFlyoutListItemTypographyRegularXs);
        color: var(--sbkFlyoutListItemFgColorEnabled);

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                @media all and (min-width: ${breakpointMd}) {
                    font: var(--sbkFlyoutListItemTypographyBoldXs);
                    color: var(--sbkFlyoutListItemFgColorHover);
                }
            }
        }

        &:active {
            color: var(--sbkFlyoutListItemFgColorActive);
        }

        &:focus-visible {
            outline: var(--sbkFlyoutListItemFocus);
        }

        @media all and (max-width: ${breakpointXl}) {
            padding: var(--sbkFlyoutListItemSpacingSmallScrGapVertical) 0;
        }
    }

    ::slotted(sbk-link) {
        width: 100%;
    }
`;
