import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const sbkNavFlyoutListStyle = css`
    .nav-flyout-list {
        list-style-type: none;
        margin: 0;
        padding-left: 0;
        column-count: 2;
        column-gap: var(--sbkFlyoutListItemSpacingLargeScrGapColumn);

        @media all and (max-width: ${breakpointXl}) {
            column-count: unset;
            padding-top: 0;
            display: block;
            border-bottom: var(--sbkFlyoutListItemDivider);
        }
    }

    ::slotted([slot="titleMenu"]) {
        padding-bottom: var(--sbkFlyoutListItemSpacingLargeScrGapVertical);
    }

    @media all and (max-width: ${breakpointXl}) {
        ::slotted([slot="titleMenu"]) {
            padding-bottom: var(--sbkFlyoutListItemSpacingSmallScrGapVertical);
            height: var(--sbkFlyoutListItemSpacingLargeScrGapColumn) !important;
            width: 100%;
        }
    }

    .nav-flyout-list__wrapper.secondLevel {
        display: none;
    }

    .nav-flyout-list__wrapper.secondLevel.expanded {
        display: block;
    }

    .nav-flyout-list__wrapper.thirdLevel {
        display: none;
    }

    .nav-flyout-list__wrapper.thirdLevel.expanded {
        display: block;
    }
`;
