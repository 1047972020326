import {customElement, property} from 'lit/decorators.js';
import { sbkMainNavItemsStyle } from './main-nav-items.styles';
import {html, LitElement} from 'lit';
import {classMap, ClassInfo} from "lit/directives/class-map.js";


@customElement('sbk-main-nav-items')
export class SbkMainNavItems extends LitElement {

    @property({type: Boolean})
    onScroll: boolean = false;

    static get styles() {
        return sbkMainNavItemsStyle;
    }

    render() {
        return html`
            <ul class=${classMap(this.getClassMap())}>
                <slot></slot>
            </ul>
        `;
    }

    getClassMap(): ClassInfo {
        return {
            'on-scroll': this.onScroll
        };
    }
}
