import {customElement, property} from 'lit/decorators.js';
import {sbkNavFlyoutListStyle} from './nav-flyout-list.styles';
import {html, LitElement} from 'lit';
import {SbkNavFlyoutItem} from "@/components/nav-flyout-item/nav-flyout-item";
import {SbkLink} from "@/components/link/link";


@customElement('sbk-nav-flyout-list')
export class SbkNavFlyoutList extends LitElement {

    @property({type: Boolean, attribute: 'is-second-level'})
    isSecondLevel = false;

    @property({type: Boolean, attribute: 'is-third-level'})
    isThirdLevel = false;

    @property({type: Boolean})
    expanded = false;

    static get styles() {
        return sbkNavFlyoutListStyle;
    }

    constructor() {
        super();
        this._setBorderBottom()
    }

    render() {
        return html`
            <div
                class="nav-flyout-list__wrapper ${this.isSecondLevel ? 'secondLevel' : ''} ${this.isThirdLevel ? 'thirdLevel' : ''} ${this.expanded ? 'expanded' : ''}"
                id=${this.id}>
                <slot name="titleMenu"></slot>
                <ul class="nav-flyout-list">
                    <slot @click=${this._triggerDeeperLevel}></slot>
                </ul>
            </div>
        `;
    }

    _triggerDeeperLevel(event: Event) {
        event.preventDefault()
        let relatedLink: SbkLink | null;
        if ((event.target as Element).tagName.toLowerCase() === 'sbk-nav-flyout-item') {
            relatedLink = (event.target as Element).querySelector('sbk-link');
        } else {
            relatedLink = (event.target as SbkLink);
        }
        const relatedId = relatedLink?.getAttribute('data-related-flyout-list');
        if (relatedId) {
            const triggerEvent = new CustomEvent('triggerDeeperLevel', {
                bubbles: true,
                composed: true,
                detail: {
                    id: relatedId,
                    isSecondLevel: this.isSecondLevel
                }
            })
            this.dispatchEvent(triggerEvent)
        } else if (relatedLink && relatedLink.href) {
            location.href = relatedLink.href
        }
    }

    _setBorderBottom() {
        const items = Array.from(this.querySelectorAll('sbk-nav-flyout-item'));
        const middleIndex = Math.floor(items.length / 2);
        const item = items[middleIndex - (items.length % 2 === 0 ? 1 : 0)] as SbkNavFlyoutItem;
        item.classList.add('border-bottom');
    }

}
