import {customElement} from 'lit/decorators.js';
import {sbkNavFlyoutItemStyle} from './nav-flyout-item.styles';
import {html, LitElement} from 'lit';


@customElement('sbk-nav-flyout-item')
export class SbkNavFlyoutItem extends LitElement {

    static get styles() {
        return sbkNavFlyoutItemStyle;
    }

    connectedCallback() {
        super.connectedCallback();
        this.setAttribute('role', 'listitem');
    }

    render() {
        return html`
            <div class="nav-flyout-item">
                <slot></slot>
            </div>
        `;
    }
}
