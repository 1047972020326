import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const sbkMainNavItemStyle = css`

    .responsive-hide {
        @media all and (max-width: ${breakpointXl}) {
            display: none;
        }
    }

    .responsive-show {
        display: none;
        @media all and (max-width: ${breakpointXl}) {
            display: block;
        }
    }

    div {
        height: 100%;
        @media all and (max-width: ${breakpointXl}) {
            padding-left: var(--sbkTopNavBarSpacingSmallScreenGap);
        }
    }

`;
